import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "../../../../helpers/useDebounce";
import { api } from "../../../../helpers/api";
import { MultiSelect } from "../../../../components/MultiSelect";

export const SubTopicSelect = ({ onChange, value, label, topics }: any) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const fetchCategories = useCallback(async () => {
    try {
      setLoading(true);

      // Handle case when topics is empty or invalid
      if (!Array.isArray(topics) || topics.length === 0) {
        setCategories([]);
        setLoading(false);
        return;
      }

      // Fetch subtopics for all topics
      const subtopicsPromises = topics.map((topic) => {
        if (!topic?.value) return Promise.resolve([]);
        return api
          .getSubTopics(topic.value)
          .then((res) => {
            // Transform the string array into object array with name property
            return (res?.data?.sub_topic || []).map((subtopic) => ({
              name: subtopic,
              bg_color_code: "#F5F5F5", // default background color
              text_color_code: "#000000", // default text color
            }));
          })
          .catch((error) => {
            console.error(
              `Error fetching subtopics for ${topic.value}:`,
              error
            );
            return [];
          });
      });

      const allSubtopics = await Promise.all(subtopicsPromises);

      // Flatten and deduplicate subtopics by name
      const uniqueSubtopics = Array.from(
        new Map(allSubtopics.flat().map((item) => [item.name, item])).values()
      );

      setCategories(uniqueSubtopics);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  }, [page, debouncedSearchValue, topics]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <div className="flex flex-col gap-1.5">
      <label className="label text-black font-semibold text-sm">
        {label || "Category"}
      </label>
      <MultiSelect
        name="topic"
        onInputChange={(inputValue) => setSearchValue(inputValue)}
        options={categories?.map((category) => ({
          label: category.name,
          value: category.name,
        }))}
        onChange={onChange}
        value={value.map((v: any) => ({ value: v.value, label: v.label }))}
        multiValue={(base: any, data: any) => {
          const category = categories.find((c) => c.name === data.data.value);
          return {
            ...base,
            // backgroundColor: `${category?.bg_color_code} !important`,
          };
        }}
        renderLabel={(data, meta) => {
          const category = categories.find((c) => c.name === data.value);
          console.log({ category, data, meta, value });
          if (meta.context === "value") {
            return (
              <span
                style={
                  {
                    // color: category?.text_color_code,
                  }
                }
              >
                {data.label}
              </span>
            );
          }
          return (
            <div
              className={`h-[23px] w-fit px-2 py-1.5 bg-neutral-100 text-black text-[13px] font-medium rounded border border-black/5 justify-start items-center gap-1 flex`}
              style={{
                backgroundColor: category?.bg_color_code,
                color: category?.text_color_code,
              }}
            >
              {data.label}
            </div>
          );
        }}
      />
    </div>
  );
};
