import { useState } from "react";
import FullScreenModal from "../../../components/FullScreenModal";
import { api } from "../../../helpers/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LessonConfirmDelete } from "./components/Confirmations";
import { Banner } from "./components/Banner";
import {
  ConfirmBlock,
  ConfirmDelete,
  ConfirmVideoDelete,
} from "../Confirmations";
import { SubVideoList } from "./components/SubVideoList";
import { SingleVideoPopup } from "./components/SingleVideoPopup";
import { TrailerVideo } from "./components/TrailerVideo";

export function ContentDetails({ isOpen, onClose, course, refresh }) {
  const [deletePostConfirmation, setDeletePostConfirmation] =
    useState<any>(false);
  const [editVideoPopupOpen, setEditVideoPopupOpen] = useState<any>(false);
  const [deleteVideoPopupOpen, setDeleteVideoPopupOpen] = useState<any>(false);
  const [singleVideoPopupOpen, setSingleVideoPopupOpen] = useState<any>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState<any>(false);
  // const handleDelete = () => {
  //   setIsDeletingPost(true);
  //   api
  //     .deletePost(deletePostConfirmation?.id)
  //     .then(() => {
  //       ShowToast({
  //         message: "Post deleted successfully",
  //         type: "success",
  //       });
  //       refresh();
  //       setDeletePostConfirmation(false);
  //       queryClient.invalidateQueries({ queryKey: ["posts"] });

  //       setIsDeletingPost(false);
  //     })
  //     .catch(() => {
  //       ShowToast({
  //         message: "Failed to delete post",
  //         type: "error",
  //       });
  //       setIsDeletingPost(false);
  //     });
  // };
  const {
    data: userDetailsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["course-details", course?.video_id],
    queryFn: () => api.getCourseDetails(course?.video_id),
    enabled: !!course?.video_id,
  });
  const { data: likedData } = useQuery({
    queryKey: ["liked-posts", course?.video_id],
    queryFn: () =>
      api.getUserLikedCourses({
        id: course?.video_id,
        page: 1,
        limit: 10,
      }),
    enabled: !!course,
  });

  console.log(isOpen, "isOpen", course, userDetailsData, deleteVideoPopupOpen);
  return (
    <>
      <div className="fixed inset-0 flex bg-gray-900 bg-opacity-50 z-50 pt-12">
        <FullScreenModal isOpen={isOpen} onClose={onClose}>
          <div className="max-w-[898px] mx-auto mt-[34px]">
            <Banner
              details={course}
              onDeactivate={() => setIsBlockModalOpen(true)}
              onDelete={() => setIsDeleteModalOpen(true)}
            />
            <TrailerVideo
              data={userDetailsData?.data?.publisherVideo || {}}
              onUpdate={() => {
                refresh();
                refetch();
              }}
            />
            <SubVideoList
              items={userDetailsData?.data?.publisherVideo?.subVideo || []}
              onAdd={() => setSingleVideoPopupOpen(true)}
              onEdit={(data) => setEditVideoPopupOpen(data)}
              onDelete={(data) => setDeleteVideoPopupOpen(data)}
              data={course}
              onUpdate={() => {
                refresh();
                refetch();
              }}
            />
          </div>
        </FullScreenModal>
        {deletePostConfirmation.id && (
          <LessonConfirmDelete
            onClose={() => setDeletePostConfirmation(false)}
            refetch={refresh}
            lessonId={deletePostConfirmation.id}
            name={deletePostConfirmation?.title}
          />
        )}
        {deleteVideoPopupOpen && (
          <ConfirmVideoDelete
            onClose={() => setDeleteVideoPopupOpen(false)}
            videoId={deleteVideoPopupOpen?.sub_video_id}
            refetch={() => {
              refresh();
              setDeleteVideoPopupOpen(false);
              refetch();
            }}
            name={deleteVideoPopupOpen?.title}
          />
        )}

        {isDeleteModalOpen && (
          <ConfirmDelete
            userId={course?.video_id || ""}
            refetch={() => {
              // queryClient.invalidateQueries({ queryKey: ["users"] });
              // queryClient.invalidateQueries({
              //   queryKey: ["chart", user?.user_id],
              // });
              refresh();
              onClose();
            }}
            onClose={() => setIsDeleteModalOpen(false)}
            name={course?.title}
          />
        )}
        {isBlockModalOpen && (
          <ConfirmBlock
            userId={course?.video_id || ""}
            refetch={() => {
              // queryClient.invalidateQueries({ queryKey: ["users"] });
              // queryClient.invalidateQueries({
              //   queryKey: ["chart", user?.user_id],
              // });
              refresh();
              onClose();
            }}
            onClose={() => setIsBlockModalOpen(false)}
            name={course?.title}
          />
        )}
        {editVideoPopupOpen && (
          <SingleVideoPopup
            open={editVideoPopupOpen}
            onClose={() => setEditVideoPopupOpen(false)}
            onUpdate={() => {
              refresh();
              refetch();
              setEditVideoPopupOpen(false);
            }}
            data={course}
            videoDetails={editVideoPopupOpen}
          />
        )}
        {singleVideoPopupOpen && (
          <SingleVideoPopup
            open={singleVideoPopupOpen}
            onClose={() => setSingleVideoPopupOpen(false)}
            onUpdate={() => {
              refresh();
              refetch();
              setSingleVideoPopupOpen(false);
            }}
            data={course}
          />
        )}
      </div>
    </>
  );
}
