import { SortableContainer, SortableElement } from "react-sortable-hoc";
import DraggableRow from "./DraggableRow";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../../../helpers/api";
import { ShowToast } from "../../../../components/toast";
import { VideoForm } from "./VideoForm";

const SortableItem = SortableElement<any>(
  ({
    value,
    number,
    total,
    onEdit,
    onDelete,
  }: {
    value: any;
    number: number;
    total: number;
    onEdit: any;
    onDelete: any;
  }) => (
    <DraggableRow
      data={value}
      number={number}
      total={total}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  )
);

const SortableList = SortableContainer<any>(
  ({ items, onEdit, onDelete }: any) => {
    return (
      <div className="flex flex-col gap-3">
        {items?.map((value: any, index: number) => (
          <SortableItem
            key={`item-${value.banner_id}`}
            index={index}
            value={value}
            number={index + 1}
            total={items?.length}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </div>
    );
  }
);

const arrayMoveMutate = ({ array, from, to }: any) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = ({ array, from, to }: any) => {
  array = array.slice();
  arrayMoveMutate({ array, from, to });
  return array;
};

export const targetHasProp = (
  target: Element | null,
  hasProp: (target: Element) => boolean
): boolean => {
  while (target) {
    if (hasProp(target)) {
      return true;
    }
    // eslint-disable-next-line no-param-reassign
    target = target.parentElement;
  }
  return false;
};

export function SubVideoList({
  items,
  onAdd,
  onEdit,
  onDelete,
  data,
  onUpdate,
}: any) {
  console.log(items);
  const [videos, setVideos] = useState(items);
  const [newVideo, setNewVideo] = useState({});
  const [saving, setSaving] = useState(false);

  const onSaveVideo = async () => {
    console.log(newVideo, data);
    try {
      setSaving(true);
      const res = await api.saveSubVideo({
        ...newVideo,
        video_id: data?.video_id,
      });

      setSaving(false);
      console.log(res);
      if ([200, 201].includes(res?.status)) {
        onUpdate();
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  const onVideoDataChange = (data: any) => {
    console.log(data);
    if (data) {
      setNewVideo(data);
    }
  };

  const mutate = useCallback((videoIds: string[]) => {
    console.log(videoIds);
    api
      .sortSubVideos({
        sub_videos: videoIds,
      })
      .then(() => {
        ShowToast({
          message: "Videos reordered successfully",
          type: "success",
        });
      })
      .catch((err) => {
        ShowToast({
          message: err.response.data.message || "Failed to reorder videos",
          type: "error",
        });
      });
  }, []);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setVideos((oldItems: any[]) =>
        arrayMove({
          array: oldItems,
          from: oldIndex,
          to: newIndex,
        })
      );
      const newVideos = arrayMove({
        array: [...videos],
        from: oldIndex,
        to: newIndex,
      });
      const videoIds = newVideos.map((video: any) => video.sub_video_id);
      console.log(newVideos);
      mutate(videoIds);
    },
    [videos, mutate]
  );

  const shouldCancelSortStart = (coach: any): boolean => {
    // Cancel sort if a user is interacting with a given element
    return targetHasProp(coach.target as Element, (el) => {
      return ["button"].includes(el.tagName.toLowerCase());
    });
  };

  useEffect(() => {
    setVideos(items);
  }, [items]);

  return (
    <div>
      <div className="h-[22px] justify-between items-center flex w-full">
        <div className="text-black text-lg font-bold font-['Montserrat']">
          Videos ({videos?.length})
        </div>
        <button
          onClick={onAdd}
          className="text-[#355e3b] text-[13px] font-medium font-['Montserrat']"
        >
          + Add another video
        </button>
      </div>
      {videos?.length === 0 && (
        <div className="mt-4">
          <VideoForm
            data={newVideo}
            onChange={onVideoDataChange}
            onSave={onSaveVideo}
            loading={saving}
          />
        </div>
      )}
      <SortableList
        items={videos}
        onSortEnd={onSortEnd}
        shouldCancelStart={shouldCancelSortStart}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </div>
  );
}
